import{ Steps } from "antd";
export type StepsStatusProps = {
    statusStep: any;
    direction: "vertical" | "horizontal"
};
export const StepsProfileStatus: React.FC<StepsStatusProps> = ({
    statusStep,
    direction = "vertical"
}) => {

    let status = 0;
     switch(statusStep){
        case 0:
        case 3:
            status = 0;
            break;
        case 4:            
        case 6:
            status = 1;
            break;
        case 5:
            status = 2;
            break;
        case 1:
        case 2:
            status = 3;
            break; 
    }    

    return (<Steps
            direction={direction}
            size="small"
            labelPlacement="vertical" 
            current={status}
            style={{
                maxWidth: "900px",
                padding: "12.5px",
                margin: "20px auto"
            }}
            items={[
                {
                    title: 'Envio de Dados + Endereço',
                    // description: status>0 ? "✅ concluído" : "❌ pendente"
                },
                {
                    title: 'Anexar Selfie e Documentos',
                    // description: status>1 ? "✅ concluído" : "❌ pendente"
                },
                {
                    title: 'Análise de Cadastro',
                    // description: status>=2 ? "✅ concluído" : "❌ pendente"
                },
            ]}
        />)
}